import React from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'; // remove this when skills tab is released and isDeveloper is removed

import { color } from '../../../styles';
import { locale } from '../../../helpers/locale';
import { withSiteHOC } from '../../../hocs/withSite';

import { Assessment, WorkOpportunities } from 'containers';
import { Card, ContentWrap, SubNav } from 'components';

import Visits from './Visits';
import Activity from './Activity';
import Geography from './Geography';
import Referrals from './Referrals';
import Resumes from './Resumes';
import Skills from './Skills';

const StyledCard = styled(Card)`
  padding: 0;
`;

const Padding = styled.div`
  padding: 4rem 2rem 2.5rem;
`;

const Line = styled.hr`
  width: 100%;
  border: 2px solid ${color.lightGray};
  position: absolute;
  margin: 0;
  top: 61px;
`;

const Statistics = withSiteHOC()(
  ({
    assessmentLengths,
    employerPortal,
    isDeveloper,
    nation,
    resumeBuilder,
    skillsMatchSettings: { hasSkillsAssessment }
  }) => {
    const navigation = [
      {
        title: 'Total Visits',
        path: '/analytics/statistics/visits',
        component: Visits
      },
      {
        title: 'Top Activity',
        path: '/analytics/statistics/activity',
        component: Activity
      },
      {
        title: 'Geography',
        path: '/analytics/statistics/geography',
        component: Geography
      },
      assessmentLengths.length > 0 && {
        title: 'Interests',
        path: '/analytics/statistics/assessment',
        component: Assessment
      },
      isDeveloper &&
        hasSkillsAssessment && {
          title: 'Skills',
          path: '/analytics/statistics/skills',
          component: Skills
        },
      resumeBuilder && {
        title: `${locale.resume[nation]}s`,
        path: '/analytics/statistics/resumes',
        component: Resumes
      },
      {
        title: 'Top Referrals',
        path: '/analytics/statistics/referrals',
        component: Referrals
      },
      employerPortal && {
        title: 'Work Opportunities',
        path: '/analytics/statistics/work-opportunities',
        component: WorkOpportunities
      }
    ].filter(Boolean);

    return (
      <ContentWrap>
        <StyledCard>
          <Line />
          <SubNav navigation={navigation} backgroundColor="#ffffff" linkColor="#323232" />
          <Padding>
            <Switch>
              {navigation.map((nav, index) => (
                <Route
                  key={`route-${index}}`}
                  path={nav.path}
                  render={props => <nav.component {...props} />}
                />
              ))}
              <Redirect to="/analytics/statistics/visits" />
            </Switch>
          </Padding>
        </StyledCard>
      </ContentWrap>
    );
  }
);

// export default Statistics;

export const mapStateToProps = ({ profile: { isDeveloper } }) => ({
  isDeveloper
});
export default connect(mapStateToProps)(Statistics); // remove this when skills tab is released and isDeveloper is removed
